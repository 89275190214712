<template>
    <div>
        <sui-form-field required :error="location.errorTitle">
            <label>Title</label>
            <input type="text" name="location-title" placeholder="Title" v-model="location.title">
        </sui-form-field>
        <sui-form-field required :error="location.errorAddress">
            <label>Address</label>
            <input type="text" name="location-address" placeholder="Address" v-model="location.address">
        </sui-form-field>
        <sui-form-field required :error="location.errorStatus">
            <label>Status</label>
            <sui-dropdown
                    fluid
                    :options="statusOptions"
                    search
                    selection
                    v-model="location.status"
            />
        </sui-form-field>
        <sui-form-field>
            <label>Users</label>
            <sui-dropdown
                    fluid
                    :options="usersOptions"
                    search
                    selection
                    multiple
                    v-model="location.users"
            />
        </sui-form-field>
        <sui-form-field>
            <p class="field-detail">These users will be allowed to access all the resources related to this location</p>
        </sui-form-field>


    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "LocationForm",
        props: {
            location: {
                title: String,
                address: String,
                status: String,

                users: Array,

                errorTitle: Boolean,
                errorAddress: Boolean,
                errorStatus: Boolean,
            }
        },
        created: function () {
            this.loadUsers();
        },
        methods: {
            loadUsers: function () {
                console.log("loadUsers");
                axios({
                    method: "POST",
                    url: "/api/user/list",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),

                        limit: 0,
                        offset: 0,

                        firstName: "",
                        lastName: "",
                        email: "",
                        status: "",
                        role: "",
                    }
                }).then((response) => {
                    const {rows, count} = response.data;
                    console.log(rows);
                    console.log(count);
                    this.usersOptions = rows.map((i)=>{
                        return {
                            value: i.id,
                            text: `[${i.role}] ${i.firstName} ${i.lastName} - ${i.email}`
                        }
                    })
                });

            }
        },
        data: function () {
            let opts = ['Active', 'Inactive']

            return {
                statusOptions: opts.map((i) => {
                    return {
                        value: i,
                        text: i,
                    }
                }),
                usersOptions: [],
            }
        }
    }
</script>

<style scoped>
    p.field-detail {
        margin-top: -10px;
        color: grey;
    }
</style>
