<template>
    <div id="add-location">

        <h2 is="sui-header" class="dividing">Add Location</h2>
        <sui-form :loading="loading" :error="errorTitle !== ''" :success="successTitle !== ''">
            <success-message :title="successTitle" :reasons="successList"></success-message>
            <error-message :title="errorTitle" :reasons="errorList"></error-message>
            <location-form :location="location"></location-form>
        </sui-form>

        <sui-divider></sui-divider>
        <sui-button type="submit" color="blue" @click.prevent="save">Create Location</sui-button>
        <sui-button floated="right" @click.prevent="$router.push('/dashboard/location')">Cancel</sui-button>

    </div>
</template>

<script>
    import axios from "axios";

    import LocationForm from "@/views/dashboard/location/LocationForm";
    import ErrorMessage from "@/components/ErrorMessage";
    import SuccessMessage from "@/components/SuccessMessage";

    export default {
        name: "LocationAdd",
        components: {LocationForm, ErrorMessage, SuccessMessage},

        data: function () {
            return {
                location: {
                    title: "",
                    address: "",
                    status: "Active",

                    users: [],

                    errorTitle: false,
                    errorAddress: false,
                    errorStatus: false,
                },
                loading: false,

                invitationLink: "",
                defaultPassword: "",

                errorTitle: "",
                errorList: [],

                successTitle: "",
                successList: [],
            }
        },
        methods: {
            save: async function () {
                this.location.errorTitle = this.location.title.trim() === "";
                this.location.errorAddress = this.location.address.trim() === "";
                this.location.errorStatus = this.location.status.trim() === "";

                if (!(this.location.errorTitle || this.location.errorAddress || this.location.errorStatus)) {
                    axios({
                        method: "POST",
                        url: "/api/location/create",
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                        data: {
                            token: this.$cookies.get('token'),
                            tokenId: this.$cookies.get('tokenId'),
                            
                            title: this.location.title,
                            address: this.location.address,
                            status: this.location.status,
                            users: this.location.users
                        }
                    }).then((result) => {
                        if (result.data.rowId) {
                            this.successTitle = "Created new location!"
                            this.errorTitle = "";
                            this.errorList = [];
                        } else {
                            this.errorTitle = "Cannot create new location!";
                            this.errorList = [];
                            this.errorList.push("Network connection issue.");
                        }

                    }).catch((err) => {
                        console.log(err);
                        this.errorTitle = "Cannot create new location";
                        this.errorList = [];
                        this.errorList.push("Network connection issue.");
                    }).finally(() => {
                        this.location.errorTitle = false;
                        this.location.errorAddress = false;
                        this.location.errorStatus = false;
                    })
                }
            }

        }
    }
</script>

<style scoped>

</style>
